import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "48",
  height: "48",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M3.61 1.814 13.793 12 3.611 22.186a1 1 0 0 1-.61-.92V2.735a1 1 0 0 1 .609-.921M14.5 12.707l2.302 2.302-10.937 6.333zm3.199-3.198 2.807 1.626a1 1 0 0 1 0 1.73l-2.808 1.626L15.207 12zM5.865 2.658 16.803 8.99 14.5 11.293z" }, null, -1)
  ])))
}
export default { render: render }